import React, { useEffect, useState } from "react";
import { lazy } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { Routes, Route } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
const InstructorLayout = lazy(() => import("../layouts/FullLayout/InstructorLayout.js"));
const CisoLayout = lazy(() => import("../layouts/FullLayout/CisoLayout.js"));
const AdminLayout = lazy(() => import("../layouts/FullLayout/AdminLayout.js"));
const ReviewerLayout = lazy(() => import("../layouts/FullLayout/ReviewerLayout.js"));

/***** Admin Pages******/
const Dashboard1 = lazy(() => import("../views/User/Dashboard1.js"));
const Domains = lazy(() => import("../views/User/Domains.js"));
const QuizPage = lazy(() => import("../views/User/QuizPage.js"));
const Instructions = lazy(() => import("../views/User/Instructions.js"));
const ResultPage = lazy(() => import("../views/User/ResultPage.js"));
const Home = lazy(() => import("../views/Home.js"));
const HomeTraining = lazy(() => import("../views/HomeTraining.js"));
const HomeCertification = lazy(() => import("../views/HomeCertification.js"));
const HomeDomains = lazy(() => import("../views/HomeDomains.js"));
const HomeComing = lazy(() => import("../views/HomeComing.js"));
const ContactUs = lazy(() => import("../ContactUs.js"));
const About = lazy(() => import("../views/About.js"));
const NotFound = lazy(() => import("../NotFound.js"));

const Home1 = lazy(() => import("../views/Home1.js"));
const Home2 = lazy(() => import("../views/Home2.js"));
const HomeBody2 = lazy(() => import("../layouts/FullLayout/Body/HomeBody2.js"));
const Home3 = lazy(() => import("../views/Home3.js"));
// const Login = lazy(() => import("../views/Login.js"));
// const Register = lazy(() => import("../views/Register.js"));
// const Profile = lazy(() => import("../views/User/Profile.js"));
const Ivp = lazy(() => import("../IVP/ivp.js"));
const Cisodashboard = lazy(() => import("../views/CISO/cisodashboard.js"));
const CisoCalendar = lazy(() => import("../NewHomePage/CisoCalendar.js"));

/***** Instructor Routes******/
const Dashboard = lazy(() => import("../views/Instrutor/Dashboard.js"));
// const ViewQuestions = lazy(() => import("../views/Instrutor/ViewQuestions.js"));
const ViewQuestions = lazy(() => import("../views/Instrutor/TabsCard.js"));
const QuestionUpload = lazy(() => import("../views/Instrutor/QuestionUpload.js"));
const InstructorRemarks = lazy(() => import("../views/Instrutor/InsRemarks.js"));

/***** Admin Routes******/
const AdminDashboard = lazy(() => import("../views/Admin/admindashboard.js"));
const UserManagement = lazy(() => import("../views/Admin/UserManagement.js"));
const ManageQuestions = lazy(() => import("../views/Admin/UpdateParameters.js"));
const TrainingCalender = lazy(() => import("../views/Admin/TrainingCalender.js"));
const DailyReport = lazy(() => import("../views/Admin/DailyReport.js"));
const ExamReport = lazy(() => import("../views/Admin/ExamReport.js"));

/***** Reviewer Routes******/
const ReviwerDashboard = lazy(() => import("../views/Reviewer/reviewerdashboard.js"));
const QuestionApproval = lazy(() => import("../views/Reviewer/QuestionApproval.js"));
const AllRemarks = lazy(() => import("../views/Reviewer/AllRemarks.js"));

/*****Routes******/

// const LoggedIn = Cookies.get("session_key");
const isAuthenticated = Cookies.get("access_token");

let decodedToken = null;
let userRole = null;

if (isAuthenticated) {
  decodedToken = jwtDecode(isAuthenticated);
  userRole = decodedToken ? decodedToken.role : null;
}


const protectedRoutes =
  userRole === "user"
    ? [
        {
          element: <CisoLayout />,
          children: [{ path: "/ciso", element: <Cisodashboard /> }],
        },
        {
          element: <FullLayout />,
          children: [
            { path: "/dashboard", element: <Dashboard1 /> },
            // { path: "/profile", element: <Profile /> },
            { path: "/form", element: <Domains /> },
            { path: "/instructions", element: <Instructions /> },
            { path: "/quiz", element: <QuizPage /> },
            { path: "/results", element: <ResultPage /> },
          ],
        },
      ]
    : userRole === "instructor"
    ? [
        {
          element: <InstructorLayout />,
          children: [
            { path: "/dashboard1", element: <Dashboard /> },
            { path: "/uploadquestions", element: <QuestionUpload /> },
            { path: "/questions", element: <ViewQuestions /> },
            { path: "/question_remarks", element: <InstructorRemarks /> },
          ],
        },
      ]
    : userRole === "admin"
    ? [
        {
          element: <AdminLayout />,
          children: [
            { path: "/admindashboard", element: <AdminDashboard /> },
            { path: "/usermanagement", element: <UserManagement /> },
            { path: "/managequestions", element: <ManageQuestions /> },
            { path: "/trainingcalender", element: <TrainingCalender /> },
            { path: "/userreports", element: <DailyReport /> },
            { path: "/examreport", element: <ExamReport /> },
          ],
        },
      ]
    : userRole === "reviewer"
    ? [
        {
          element: <ReviewerLayout />,
          children: [
            { path: "/reviewerdashboard", element: <ReviwerDashboard /> },
            { path: "/question_approval", element: <QuestionApproval /> },
            { path: "/remarks", element: <AllRemarks /> },
          ],
        },
      ]
    : [];

const publicRoutes = [
  { path: "/", element: <Home /> },
  { path: "/ciso_calendar", element: <CisoCalendar /> },
  { path: "/ciso_training", element: <HomeTraining /> },
  { path: "/ciso_Certification", element: <HomeCertification /> },
  { path: "/ciso_domains", element: <HomeDomains /> },
  { path: "/comingsoon", element: <HomeComing /> },
  { path: "/contact", element: <ContactUs /> },
  { path: "/about", element: <About /> },
  { path: "/ics_training", element: <Home1 /> },
  { path: "/telecom_training", element: <Home2 /> },
  { path: "/ics_training_june", element: <Home3 /> },
  { path: "/training/:eventUnicode", element: <HomeBody2 /> },
  // { path: "/login", element: <Login /> },
  // { path: "/register", element: <Register /> },
  { path: "/ivp-login/:url", element: <Ivp /> },
  { path: "*", element: <NotFound /> },
];

const ThemeRoutes = isAuthenticated
  ? [...publicRoutes, ...protectedRoutes]
  : publicRoutes;

export default ThemeRoutes;
