import axios from "axios";
import { API_BASE_URL, IVP_BASE_URL, FAAS_BASE_URL, LMS_BASE_URL } from "../config/config";

class Services {
  Ivpauthorize(body) {
    return axios.post(IVP_BASE_URL + "tokengen", body);
  }

  Userinfo(access_token, refresh_token) {
    return axios.post(IVP_BASE_URL + "userinfo", refresh_token, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  IVPRewardpoints(data) {
    return axios.post(IVP_BASE_URL + "/totalRewardPoint" , data);
}

  IVPRewardpointsDetails(data) {
    return axios.post(IVP_BASE_URL + "/lastActivityByApp" , data);
  }

  // IVPLogout(body) {
  //   return axios.post(IVP_BASE_URL + "logout?client_id=ciso", body);
  // }
  IvpLogout(user_id) {
    return axios.post(IVP_BASE_URL + "ivplogout", { user_id });
  }

  IVPValidateAccessTokengenByRefreshToken(body) {
    return axios.post(IVP_BASE_URL + "/accessTokengen?client_id=ciso", body);
  }

  IvpLogin(data) {
    return axios.post(API_BASE_URL + "/ivp_login/", data);
  }

  IvpProfile(uid) {
    return axios.post(IVP_BASE_URL + "ivp/profile/", { uid });
  }

  createEvent(eventData) {
    return axios.post(IVP_BASE_URL + "ivp/createEvent/", eventData);
  }

  updateEvent(eventData) {
    return axios.post(IVP_BASE_URL + "ivp/updateEvent/", eventData);
  }
  getfaasdetails(event_unicode) {
    return axios.post(FAAS_BASE_URL + "/geteventurl/", event_unicode);
  }

  IvpTrainingComponents() {
    return axios.get(IVP_BASE_URL + "getcomponent");
  }
  IvpTrainingClusters() {
    return axios.get(IVP_BASE_URL + "thematicarea");
  }

  getAllStates() {
    return axios.get(IVP_BASE_URL + "getallstate");
  }

  getDistrictsByState(stateId) {
    return axios.get(`${IVP_BASE_URL}getdistrictbystate?state=${stateId}`);
  }

  getintendedusers() {
    return axios.get(IVP_BASE_URL + "user_category");
  }

  getAllTrainings() {
    return axios.get(IVP_BASE_URL + "ivp/eventList/");
  }
  getEventUsers(eventUnicode) {
    return axios.get(`${IVP_BASE_URL}ivp/eventUserList/${eventUnicode}/`);
  }

  getServices(eventUnicode) {
    return axios.get(`${IVP_BASE_URL}ivp/eventServiceList/${eventUnicode}/`);
  }
  fetchServices(event_unicode) {
    return axios.get(`${IVP_BASE_URL}ivp/eventServiceList/${event_unicode}/`);
  }
  GetEventCount() {
    return axios.get(`${IVP_BASE_URL}ivp/eventCount/`);
  }

  fetchCoursesFromLMS() {
    const apiUrl = `${LMS_BASE_URL}/webservice/rest/server.php`;
    const params = {
      wsfunction: 'core_course_get_courses',
      moodlewsrestformat: 'json',
      // wstoken: 'a3bea617416c5cabd4dd5f641bd7bedf'
      // wstoken : '07ca0988aaecf1d0e262c69a7b47d2dc'
      wstoken : '43ec19ed80019040b607c7750e3c7452'
    };
    const urlWithParams = `${apiUrl}?wsfunction=${params.wsfunction}&moodlewsrestformat=${params.moodlewsrestformat}&wstoken=${params.wstoken}`;

    return axios.get(urlWithParams);
  }

  mapCourseToService(selectedCourse, getTrainingEventUnicode) {
    const service_uri = `${LMS_BASE_URL}/course/view.php?id=${selectedCourse.id}`;

    const data = {
      event_unicode: getTrainingEventUnicode,
      service_uri: service_uri,
      client_id: "elearning",
      service_name: selectedCourse.fullname,
      logo: "",
    };

    return axios.post(IVP_BASE_URL + "ivp/addEventSevice/" ,data);
  }


}
export default new Services();
